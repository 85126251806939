<template>
  <b-card
    v-if="projectUsers.length>0"
    no-body
    class="card-company-table"
  >
    <p><span class="text-red">*** </span>{{ $t("MESSAGE.COLOR_INDICATION") }}</p>
    <b-table
      :items="projectUsers"
      responsive
      :fields="projectUserTableColumns"
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      primary-key="id"
      class="mb-0"
    >

      <!-- Name -->
      <template #cell(name)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.user.firstname }} {{ data.item.user.lastname }}</span>
        </div>
        <small class="text-muted">{{ data.item.user.email }}</small>
      </template>
      <!-- Column: Project Role -->
      <template #cell(ProjectRole)="data">
        <div class="text-nowrap">
          <feather-icon
            :icon="resolveProjectRole(data.item.is_project_admin).Icon"
            size="18"
            class="mr-50"
            :class="`text-${resolveProjectRole(data.item.is_project_admin).variant}`"
          />
          <span class="align-text-top text-capitalize">{{ resolveProjectRole(data.item.is_project_admin).name }}</span>
        </div>
      </template>
      <!-- Column: Title -->
      <template #cell(Title)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.role }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Business unit -->
      <template #cell(BusinessUnit)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              <span>{{ data.item.user.business_unit }}</span>
            </div>
          </div>
        </div>
      </template>
      <!-- Last Login -->
      <template #cell(LastLogin)="data">
        <div class="d-flex align-items-left text-nowrap">
          <div>
            <small class="text-muted">{{ data.item.user.lastlogin ? formatDateTime(data.item.user.lastlogin) : $t('NEVER_LOGGED') }}</small>
          </div>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`project-row-${data.item.user_id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="cursor-pointer mx-1"
            @click="$router.push({ name: 'user-profile-id', params: { id: data.item.user_id }})"
          />
          <feather-icon
            :id="`project-row-${data.item.user_id}-preview-icon`"
            icon="EditIcon"
            size="16"
            class="cursor-pointer mr-1"
            @click="projectUserEdit(data.item)"
          />
          <feather-icon
            :id="`project-row-${data.item.user_id}-preview-icon`"
            icon="MailIcon"
            size="16"
            :class="data.item.is_email > 0 ? 'cursor-pointer mr-1 text-success' : 'cursor-pointer mr-1 text-warning'"
            @click="sendEmailInvitationToUser(data.item)"
          />
          <feather-icon
            :id="`game-row-${data.item.user_id}-delete-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer mr-1"
            @click="deleteProjectUser(data.item)"
          />
        </div>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import { BCard, BTable } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import { resolveTeamRole, resolveUserStatusVariant, resolveProjectRole } from '@/constants/utils'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import { mixinDate } from '@/constants/mixinDate'

export default {
  components: {
    BCard,
    BTable,
  },
  mixins: [mixinDate],
  props: {
    projectUsers: {
      type: Array,
      default: () => [],
    },
    deleteUser: {
      type: Function,
      required: true,
    },
    projectUserEdit: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const projectUserTableColumns = [
      { key: 'Name' },
      { key: 'ProjectRole' },
      { key: 'Title' },
      { key: 'BusinessUnit' },
      { key: 'LastLogin' },
      { key: 'Actions' },
    ]

    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      projectUserTableColumns,
      resolveProjectRole,
      resolveTeamRole,
      successMessage,
      showErrorMessage,
      resolveUserStatusVariant,
    }
  },
  methods: {
    ...mapActions('project', ['deleteProjectUser', 'sendProjectInvitation']),
    deleteProjectUser(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.user.firstname}  ${data.user.lastname} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteUser(data)
          }
        })
    },
    sendEmailInvitationToUser(data) {
      if ((data.user.email)) {
        const message = data.is_email === 0
          ? this.$i18n.t('MESSAGE.INVITATION_EMAIL_CONFIRMATION')
          : this.$i18n.t('MESSAGE.RE_INVITATION_EMAIL_CONFIRMATION')
        this.$bvModal
          .msgBoxConfirm(`${message} to ${data.user.firstname}  ${data.user.lastname} ?`, {
            title: 'Please Confirm',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              // eslint-disable-next-line no-param-reassign
              data.is_email = 1
              const payload = {
                id: data.project_id,
                user_id: data.user_id,
                message_type: 'email',
              }
              this.sendProjectInvitation(payload).then(response => {
                if (response) {
                  this.successMessage(this.$i18n.t('MESSAGE.INVITATION_EMAIL_SEND'))
                }
              }).catch(() => {
                this.showErrorMessage()
              })
            }
          })
      } else {
        this.errorMessage(this.$i18n.t('MESSAGE.EMAIL_UNAVAILABLE'))
      }
    },
  },
}

</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.text-red {
  color: red;
}
</style>
